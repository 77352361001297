@import "../../../globalStyles/StyleVariables.scss";

.tabTitleDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $gray60;
}

.tabTitleDiv:hover {
    color: white;
}

.tabTitleDivActive {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.tabTitleIcon {
    font-size: 20px;
}

.tabTitleIcon:hover {
    color: white;
}

.tabTitleText {
    // color: $gray60;
}

.tabTitleText:hover {
    color: white;
}