@import "../../../../globalStyles/StyleVariables.scss";

.ticker {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    >span {
        font-size: 10px;
    }

    .tickerContent {
        display: flex;
        flex-direction: row;
        align-items: center;
    
        .bigTextGreen {
            font-size: 18px;
            color: $greenPositive;
        }
        .bigTextRed {
            font-size: 18px;
            color: $redNegative;
        }

        .arrowIcon {
            width: 10px;
            margin-left: 5px;
            margin-right: 10px;
        }

        .smallText {
            font-size: 10px;
        }
    }
}

.loadingPopup {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #1e1f25e4;
    z-index: 5;
}

.fullHeight {
    height: 640px;
}

.halfHeight {
    height: 320px;
}

.hide {
    display: none;
}