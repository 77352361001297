@import '../../globalStyles/StyleVariables.scss';

.floatLabel {
  position: relative;
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 4px;
  transition: 0.3s ease all;
}

.asPlaceholder {
  color: $gray60;
  font-size: 11px !important;
}

.asLabel {
  top: -15px;
  font-size: 9px !important;
  // background: white;
  padding: 0 4px;
  margin-left: -4px;
  color: $gray60;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
}

.item {
  background-color: $gray60;
  height: 1px;
  width: 18%;
  border-radius: 5px;
  transition: all 0.8s;
}

.itemRed {
  background-color: red;
}

.itemOrange {
  background-color: orange;
}

.itemYellow {
  background-color: yellow;
}

.itemGreen {
  background-color: green;
}
