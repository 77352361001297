.ant-upload-list-picture-card-container {
    width: 170px !important;
    height: 170px !important;
    margin: 0 !important;
}

.ant-upload-list-picture-card {
    margin: 0 !important;
    padding: 0 !important;
}

.ant-upload-list {
    width: 0 !important;
}

.ant-upload-picture-card-wrapper {
    width: 0 !important;

}

// .ant-upload-list {
//     margin: 0 !important;
//     padding: 0 !important;
//     width: auto !important;
// }

.ant-upload-select-picture-card {
    width: 170px !important;
    height: 170px !important;
    background-color: transparent !important;
    border: 0 !important;
}

.ant-upload-list-item {
    background-color: transparent !important;
    border-radius: 10px !important;
    padding: 5px important;
}
