$accentColor: #1890ff;
$backgroundColor: #fff;
$cardColor: #f4f4f4;

$gray20: #1e1f25;
$gray40: #34384c;
$gray60: #5d6588;
$gray80: #a5adcf;
$gray100: #e5e6ed;

$modalBackgroundColor: #fff;
$lightGreen: #30e0a1;
$greenPositive: #0ecd81;
$darkGreen: #11cabe;
$redNegative: #fa2256;
