@import "../../../globalStyles/StyleVariables.scss";

.container {
    display: flex;
    flex-direction: column;
    background-color: $cardColor;
    border-radius: 10px;
}

.topInfoLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    position: absolute;
    width: 100%;
    height: 78px;
    background-color: #1e1f25e4;
    z-index: 1;
}

.topInfoLoadingM {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    position: absolute;
    width: 100%;
    height: 140px;
    border-radius: 10px;
    background-color: #1e1f25e4;
    z-index: 1;
}

.tradingViewChartLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    position: absolute;
    width: 100%;
    height: 410px;
}

.chartContainer {
    border-left: 1px solid $gray80;
    border-right: 1px solid $gray80;
    border-bottom: 1px solid $gray80;
}

.topInfosDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 30px;
    font-size: 12px;
    overflow-x: hidden;

    >div {
        display: flex;
        flex-direction: column;
        margin-right: 15px;
    }

    .verticalLine {
        border-right: 1px solid $gray60;
        height: 50px;
    }
}

.changeGreen {
    color: $greenPositive;
}

.changeRed {
    color: $redNegative;
}

.listTitleDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;

    >div>img {
        margin-right: 10px;
        cursor: pointer;
    }
}

.arrowButton {
    color: $gray40;
    cursor: pointer;

    &:hover {
        color: white;
        transition: all 0.5s ease;
    }
}

.horizentalListItem {
    color: $gray40;
    background-color: transparent;
    margin: 0 3px;
    font-size: 12px;
    cursor: pointer;
    border: 0;

    &:hover {
        color: white;
    }

    &:focus {
        color: $greenPositive;
    }
}

.topInfoTitleM {
    font-size: 13px;
    color: $gray40
}

.topInfoTitleSymbolM {
    font-size: 11px;
    color: $gray40
}

.topInfoTextM {
    font-size: 11px;
}