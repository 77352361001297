@import '../../../globalStyles/StyleVariables.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dontRecieveCode {
  color: $gray60;
}

.requestAgain {
  margin-left: 10px;
  color: $gray20;
}

.formTitleDiv {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  .formTitleBig {
    font-size: 26px;
    // font-weight: bold;
    color: $gray20;
  }

  .formTitleSmall {
    font-size: 12px;
    color: $gray20;
    opacity: 50%;
  }
}

.formItemLabel {
  color: $gray60;
}

.checkbox {
  color: $gray60;
}

.textbox {
  width: auto;
  height: auto;
  text-align: center;
  border-radius: 5px;
}
.textboxFilled {
  width: auto;
  height: auto;
  text-align: center;
  border-radius: 5px;
  border: 1px solid white;
}
