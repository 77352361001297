@import "../../../globalStyles/StyleVariables.scss";

body {
    background-color: $backgroundColor;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.loginCreateAccount {
    color: $gray20;
}



// @keyframes changeTransform {
//     from {
//         transform: translate(20px);
//     }

//     to {
//         transform: translate(-20px);
//     }
// }

// .animateImage {
//     display: block;
//     margin-bottom: -400px;
//     animation-duration: 5s;
//     animation-name: changeTransform;
//     animation-iteration-count: infinite;
//     animation-direction: alternate;
// }