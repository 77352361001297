@import "../../../../../globalStyles/StyleVariables.scss";

body {
    background-color: $backgroundColor;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formBody {
    background-color: $cardColor;
    padding: 30px;
    border-radius: 10px;
    margin-top: 20px;
}
