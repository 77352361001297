@import '../../../../globalStyles/StyleVariablesLight.scss';

.container {
  background-color: $cardColor;
  overflow-x: auto;
  overflow-y: auto;
  padding: 0 10px;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    overflow-y: auto;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: $gray80;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: 0;
    border-radius: 0;
    background: $cardColor;
  }
}

.tblHeaderText {
  font-size: 13px;
  font-weight: normal;
}

.td {
  // width: 100px;
  width: 33.3%;

  .tblCellDiv_alignLeft {
    display: flex;
    align-items: center;
    height: 20px;
    font-size: 12px;
  }

  .tblCellDiv_alignRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 20px;
    font-size: 12px;
  }
}

.tblHeaderDiv_alignLeft {
  display: flex;
  justify-content: flex-start;
}
.tblHeaderDiv_alignRight {
  display: flex;
  justify-content: flex-end;
}

.percentTd {
  position: absolute;
  right: 0;
  left: 0;
  direction: rtl;

  .percentdivRed {
    height: 20px;
    background-color: #f6465e81;
    opacity: 0.2;
    overflow: hidden;
  }

  .percentdivGreen {
    height: 20px;
    background-color: #0ecb838a;
    opacity: 0.2;
    overflow: hidden;
  }
}

.priceGreen {
  color: $greenPositive;
}

.priceRed {
  color: $redNegative;
}
