@import "../../../../../globalStyles/StyleVariables.scss";

body {
    background-color: $backgroundColor;
}

.container {
    display: flex;
    flex-direction: column;
}

.formBody {
    display: flex;
    flex-direction: column;

    background-color: $cardColor;
    padding: 30px;
    border-radius: 10px;
    margin-top: 20px;
}

.formTitleDiv {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    .formTitle {
        font-size: 20px;
        // font-weight: bold;
        color: $gray20;
    }
}

.iconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;

    .iconTextBig {
        color: $gray20;
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 5px;
    }

    .iconTextSmall {
        color: $gray40;
        font-size: 10px;
    }

}