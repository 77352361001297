$accentColor: #1890ff;
$backgroundColor: #141518;
$cardColor: #1e1f25;

$gray20: #e5e6ed;
$gray40: #a5adcf;
$gray60: #5d6588;
$gray80: #34384c;
$gray100: #1e1f25;

$modalBackgroundColor: #1e1f25b6;
$lightGreen: #30e0a1;
$greenPositive: #0ecd81;
$darkGreen: #11cabe;
$redNegative: #fa2256;
