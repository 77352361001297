@import '../../globalStyles/StyleVariables.scss';

.inputCustom {
  border-bottom: 1px solid $gray80;

  .anticon svg {
    color: $gray60 !important;
  }
}
.inputCustom:focus-visible {
  border-bottom: 1px solid $accentColor;
}
.inputCustom:hover {
  border-bottom: 1px solid $accentColor;
}

.inputCustomLight {
  color: #000;
  border-bottom: 1px solid #bbb;

  input {
    color: #000;
  }

  .anticon svg {
    color: $gray60 !important;
  }
}
.inputCustomLight:focus-visible {
  border-bottom: 1px solid $accentColor;
}
.inputCustomLight:hover {
  border-bottom: 1px solid $accentColor;
}

.inputCustomFilled {
  background-color: #262834;
  color: #fff;
  border: none;
  padding: 6px;
  border-radius: 5px;
}
.inputCustomFilledLight {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 6px;
  border-radius: 5px;
}
.label.as-placeholder.filled {
  top: 8px;
  left: 10px;
  color: #aaa;
}
.label.as-placeholder.filled.light {
  top: 8px;
  left: 10px;
  color: #000;
}
.float-label.filled {
  margin-top: 5px;
}

.float-label {
  position: relative;
}

.label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 4px;
  transition: 0.3s ease all;
}

.as-placeholder {
  color: $gray60;
  font-size: 11px !important;
}

.as-label {
  top: -15px;
  font-size: 9px !important;
  // background: white;
  padding: 0 4px;
  margin-left: -4px;
  color: $gray60;
}

//   .example {
//     margin: 12px;
//   }

//   /* Force update ant style */
//   .ant-input {
//     padding: 16px 12px 4px 11px;
//   }

//   .ant-select .ant-select-selector {
//     padding: 16px 10px 4px 11px;
//   }

//   .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
//     padding: 16px 10px 4px 11px;
//     height: 48px;
//   }

//   .ant-select-single .ant-select-selector .ant-select-selection-search {
//     top: 16px;
//   }
