
.btnBlock {
    background-color: #fa225436;
    color: #FA2256;
    border: 0;

    &:hover {
        background-color: #d9114036;
        color: #FA2256;
    }
}