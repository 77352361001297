@import '../../../globalStyles/StyleVariablesLight.scss';

body {
  background-color: $backgroundColor;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.topRow {
  background-color: $cardColor;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;

  label {
    color: #000;
  }

  .formItem {
    margin: 10px;
  }

  .btnSwitchToEnterprise {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    // background-color: $gray80;
    color: #000;
  }
}

.bottomRow {
  background-color: $cardColor;
  border-radius: 10px;
  // margin-top: 20px;
  padding: 50px 20px;
  width: 100%;
  margin: 10px 0 !important;

  .levelCol {
    // background-color: $cardColor;
    border-radius: 10px;
    // padding: 10px;
    margin-bottom: 40px;

    .levelColTitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 30px;
      font-weight: bold;
      font-size: medium;

      .levelColTitleLabel {
        color: #f27f16;
        font-weight: normal;
        font-size: small;
      }
    }

    .levelColRequirementDiv {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      height: 100px;
      color: $gray40;

      .requirementItem {
        margin-bottom: 5px;
      }
    }

    .levelColDescriptionDiv {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      padding-left: 20px;
      padding-right: 20px;

      .descriptionItem {
        display: flex;
        flex-direction: column;

        .descriptionTitle {
          color: $gray40;
        }

        .descriptionValue {
          color: $gray20;
        }

        .descriptionValueHorizental {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }

  // .middleCol {
  //     margin-left: 10px;
  //     margin-right: 10px;
  // }
}

.bottomRowForMobile {
  margin-top: 10px;

  .levelColForMobile {
    background-color: $cardColor;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 10px;

    .levelColTitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 30px;

      .levelColTitleLabel {
        color: #f27f16;
      }
    }

    .levelColRequirementDiv {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      height: 100px;
      color: $gray40;

      .requirementItem {
        margin-bottom: 5px;
      }
    }

    .levelColDescriptionDiv {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      padding-left: 20px;
      padding-right: 20px;

      .descriptionItem {
        display: flex;
        flex-direction: column;

        .descriptionTitle {
          color: $gray40;
        }

        .descriptionValue {
          color: $gray20;
        }

        .descriptionValueHorizental {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }

  // .middleCol {
  //     margin-left: 10px;
  //     margin-right: 10px;
  // }
}

.btnNormal {
  &:disabled {
    background-color: #eee;
    color: #999;

    &:hover {
      background-color: #eee;
      color: #999;
    }
  }
}

.btnGreen {
  &:disabled {
    background-color: #00a86d;
    color: white;

    &:hover {
      background-color: #00a86d;
      color: white;
    }
  }
}

.btnOrange {
  &:disabled {
    background-color: #f27f16;
    color: white;

    &:hover {
      background-color: #f27f16;
      color: white;
    }
  }
}

.btnRed {
  background-color: #fa2256;
  border: 0;
  color: white;

  &:hover {
    background-color: #cb0d39;
    color: white;
  }
}
