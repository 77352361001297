@import '../../../../../globalStyles/StyleVariables.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.formTitleDiv {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  .formTitleBig {
    font-size: 20px;
    // font-weight: bold;
    color: $gray20;
  }

  .formTitleSmall {
    font-size: 12px;
    color: $gray20;
    opacity: 50%;
  }
}

.comboCustom {
  border-bottom: 1px solid $gray80;
}

.comboCustom:focus-visible {
  border-bottom: 1px solid $accentColor;
}

.comboCustom:hover {
  border-bottom: 1px solid $accentColor;
}

[title='Nationality'] {
  color: $gray60 !important;
}
[title='First Name'] {
  color: $gray60 !important;
}
[title='Last Name'] {
  color: $gray60 !important;
}
[title='Middle Name'] {
  color: $gray60 !important;
}
[title='Date of Birth'] {
  color: $gray60 !important;
}

.indicatorDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 7px;
  justify-items: center;
  margin-top: 40px;
}
.indicatorItemGreen {
  border-radius: 10px;
  background-color: #30e0a1;
  width: 40px;
  margin-right: 10px;
}
.indicatorItemGray {
  border-radius: 10px;
  background-color: #5d6588;
  width: 40px;
  margin-right: 10px;
}

.selectItem {
  display: flex;
  flex-direction: row;
  align-items: center;

  > img {
    margin-right: 10px;
  }
}
