@import '../globalStyles/StyleVariables.scss';

.card {
  background-color: $cardColor;
  border-radius: 12px;
  padding: 24px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.flex-justify-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

body {
  font-size: 14px !important;
}
