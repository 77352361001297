@import '../../../../globalStyles/StyleVariables.scss';

.tradeTableTitleLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tradeTableTiteRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.tradeTableStyle {
  background-color: $cardColor;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 5px;
  border-radius: 10px;

  .ant-table-tbody > tr > td {
    border: none;
  }

  .ant-table-thead > tr > th {
    color: $gray60;
    background: $cardColor !important;
  }

  .ant-table-thead {
    height: 40px;
    font-size: 12px;
    padding: 0 !important;
  }

  .ant-table-column-sorter {
    display: none;
    // color: $gray60;
    // position: relative;
    // left: 2px;
  }

  .ant-table-column-sorters {
    // position: absolute;
    // height: 15px;
    // top: 15px;
    // left: 20%;
  }

  .ant-table-column-has-sorters::before {
    display: none !important;
  }

  .ant-table-cell::before {
    display: none !important;
  }

  .ant-table {
    background: $cardColor;
    min-width: unset !important;
    width: auto !important;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 0 !important;
    padding-right: 5px !important;
  }

  .ant-table-cell {
    // text-align: center !important;
  }
  .ant-table-body {
    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
      overflow-y: auto;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: $gray80;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: 0;
      border-radius: 0;
      background: $cardColor;
    }
  }
}
/////////////////////////////////
.tradeTableStyleLight {
  background-color: #f4f4f4;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 5px;
  border-radius: 10px;

  tr:hover {
    td {
      background-color: #fff !important;
    }
  }

  .ant-table-tbody > tr > td {
    color: #000;
    border: none;
  }

  .ant-table-thead > tr > th {
    color: #000;
    background: #f4f4f4 !important;
  }

  .ant-table-thead {
    height: 40px;
    font-size: 12px;
    padding: 0 !important;
  }

  .ant-table-column-sorter {
    display: none;
    // color: $gray60;
    // position: relative;
    // left: 2px;
  }

  .ant-table-column-sorters {
    // position: absolute;
    // height: 15px;
    // top: 15px;
    // left: 20%;
  }

  .ant-table-column-has-sorters::before {
    display: none !important;
  }

  .ant-table-cell::before {
    display: none !important;
  }

  .ant-table {
    background: #f4f4f4;
    min-width: unset !important;
    width: auto !important;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 0 !important;
    padding-right: 5px !important;
  }

  .ant-table-cell {
    // text-align: center !important;
  }
  .ant-table-body {
    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
      overflow-y: auto;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: $gray80;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: 0;
      border-radius: 0;
      background: #f4f4f4;
    }
  }
}
