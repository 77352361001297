@import "../../../../../globalStyles/StyleVariables.scss";

body {
    background-color: $backgroundColor;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formBody {
    background-color: $cardColor;
    padding: 30px;
    border-radius: 10px;
    margin-top: 20px;
}

.formTitleDiv {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    .formTitleBig {
        font-size: 20px;
        // font-weight: bold;
        color: $gray20;
    }

    .formTitleSmall {
        font-size: 12px;
        color: $gray20;
        opacity: 50%;
    }
}

.insideTextDiv {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 40px;

    .insideTextWhite {
        font-size: 12px;
        color: $gray20;
    }

    .insideTextGray {
        font-size: 12px;
        color: $gray20;
        opacity: 50%;
    }
}


.comboCustom {
    border-bottom: 1px solid $gray80;
}

.comboCustom:focus-visible {
    border-bottom: 1px solid $accentColor;
}

.comboCustom:hover {
    border-bottom: 1px solid $accentColor;
}

.formItem {
    margin-bottom: 20px;
    color: $gray60;
}

[title="Country of Issue"] {
    color: $gray60  !important;
}

.indicatorDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 7px;
    justify-items: center;
    margin-top: 40px;
}
.indicatorItemGreen {
    border-radius: 10px;
    background-color: #30E0A1;
    width: 40px;
    margin-right: 10px;
}
.indicatorItemGray {
    border-radius: 10px;
    background-color: #5D6588;
    width: 40px;
    margin-right: 10px;
}

.radioItem {
    color: gray;
}

.radioItem:hover {
    color: #F27F16 !important;
}

.fixDataDiv {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    border-bottom: 1px solid $gray80;
    padding-bottom: 5px;

    >span:nth-child(1) {
        font-size: 11px;
        color: $gray60;
    }
    >span:nth-child(2) {
        font-size: 12px;
    }
}