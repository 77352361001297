@import '../../../globalStyles/StyleVariables.scss';

/* Hide scrollbar for IE, Edge and Firefox */
.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--arrow-left,
.react-horizontal-scrolling-menu--arrow-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listboxSegmented {
  .ant-segmented-item-label {
    padding: 10px 15px !important;
    border-radius: 5px !important;
  }
}

.autoCompleteSearch_trade {
  width: 100%;

  .ant-input-affix-wrapper {
    border-radius: 15px !important;
    border: 1px solid $gray80 !important;
  }

  input {
    color: #999 !important;
  }
}
