@import '../../../globalStyles/StyleVariables.scss';

.tabTitleDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $gray60;
}

.tabTitleDiv:hover {
  color: #0fc082;
}

.tabTitleDivActive {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #0fc082;
}

.tabTitleIcon {
  font-size: 20px;
}

.tabTitleIcon:hover {
  color: #0fc082;
}

.tabTitleText {
  // color: $gray60;
}

.tabTitleText:hover {
  color: #0fc082;
}
