@import '../../../../globalStyles/StyleVariables.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.formTitleDiv {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  .formTitleBig {
    font-size: 26px;
    // font-weight: bold;
    color: $gray20;
  }

  .formTitleSmall {
    font-size: 12px;
    color: $gray20;
    opacity: 50%;
  }
}

.btnBackDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  color: $gray20;

  .icon {
    color: $gray20;
    font-size: 20px;
  }

  .btnBack {
    color: $gray20;
    padding-left: 10px;
  }
}

.btnBackDiv:hover {
  .icon {
    color: $gray40;
    font-size: 20px;
  }

  .btnBack {
    color: $gray40;
  }
}

.formItem {
  color: $gray60;
}
