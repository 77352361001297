@import "../../../../globalStyles/StyleVariables.scss";



.firstColItem {
    display: flex;
    flex-direction: column;

    .pairCoinsDiv {
        display: flex;
        flex-direction: row;
        text-align: center;
        font-size: 12px;
        margin-right: 2px;

        >span:nth-child(2) {
            color: rgb(132, 142, 156);
        }

        >span:nth-child(3) {
            color: rgb(132, 142, 156);
        }
    }

    >span {
        color: rgb(132, 142, 156);
    }
}

.secondColItem {
    display: flex;
    flex-direction: column;

    .percentRed {
        color: $redNegative;
    }

    .percentGreen {
        color: $greenPositive;
    }
}

.thirdColItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .backPositive {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0ecb8338;
        border-radius: 5px;
        width: 35px;
        height: 35px;

        >svg {
            color: #0ecb81;
            width: 20px;
        }

        >img {
            width: 20px;
        }
    }

    .backNegative {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f6465e39;
        border-radius: 5px;
        width: 35px;
        height: 35px;

        >svg {
            color: #f6465d;
            width: 20px;
        }

        >img {
            width: 20px;
        }
    }
}