@import '../../../../../globalStyles/StyleVariables.scss';

.orderHistoryTableStyle {
  background-color: $cardColor;
  padding: 10px;
  border-radius: 10px;
  width: 100%;

  .ant-table-tbody > tr > td {
    border: none;
  }

  .ant-table-thead > tr > th {
    color: $gray60;
    background: $cardColor !important;
  }

  .ant-table-thead {
    // height: 40px;
  }

  .ant-table-column-sorter {
    color: $gray60;
    // position: relative;
    left: 25px;
    top: 10px;
  }

  .ant-table-column-sorters {
    position: absolute;
    height: 20px;
    padding-bottom: 20px !important;
  }

  .ant-table-column-sorters::after {
    display: flex !important;
  }

  .ant-table-column-has-sorters {
  }

  .ant-table-column-has-sorters::before {
    display: none;
  }

  .ant-table-cell::before {
    display: none !important;
  }

  .ant-table {
    background: $cardColor;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    // padding-top: 4px !important;
    // padding-bottom: 4px !important;
    // padding-left: 4px !important;
    // padding-right: 5px !important;
  }
  .ant-table-body {
    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
      overflow-y: auto;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: $gray80;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: 0;
      border-radius: 0;
      background: $cardColor;
    }
  }
}
////////////////////////////////////
.orderHistoryTableStyleLight {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 10px;
  width: 100%;

  tr:hover {
    td {
      background-color: #fff !important;
    }
  }

  .ant-table-tbody > tr > td {
    color: #000;
    border: none;
  }

  .ant-table-thead > tr > th {
    color: #000;
    background: #f4f4f4 !important;
  }

  .ant-table-thead {
    // height: 40px;
  }

  .ant-table-column-sorter {
    color: $gray60;
    // position: relative;
    left: 25px;
    top: 10px;
  }

  .ant-table-column-sorters {
    position: absolute;
    height: 20px;
    padding-bottom: 20px !important;
  }

  .ant-table-column-sorters::after {
    display: flex !important;
  }

  .ant-table-column-has-sorters {
  }

  .ant-table-column-has-sorters::before {
    display: none;
  }

  .ant-table-cell::before {
    display: none !important;
  }

  .ant-table {
    background: #f4f4f4;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    // padding-top: 4px !important;
    // padding-bottom: 4px !important;
    // padding-left: 4px !important;
    // padding-right: 5px !important;
  }
  .ant-table-body {
    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
      overflow-y: auto;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: $gray80;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: 0;
      border-radius: 0;
      background: #f4f4f4;
    }
  }
}
