.accountSetting {
  .ant-tabs-ink-bar,
  .ant-tabs-ink-bar-animated {
    background-color: white !important;
    border-radius: 20px !important;
  }

  .ant-switch-checked {
    background-color: #30e0a1;
  }

  .ant-tabs-nav {
    // margin: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .ant-tabs-nav::before {
    display: none;
  }

  .ant-divider-horizental {
    margin: 0;
    margin-bottom: 10px;
    // background-color: transparent;
  }

  .ant-collapse-header {
    padding: 12px 0 !important;
  }
}
