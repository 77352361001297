@import '../../../../globalStyles/StyleVariables.scss';

.topItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $cardColor;
  border-radius: 10px;
  padding: 10px;

  > div:nth-child(1) {
    border-radius: 50px;
    margin-right: 10px;
    padding: 10px;
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;

    > span:nth-child(1) {
      color: white;
      font-size: 16px;
    }

    > span:nth-child(2) {
      color: $gray40;
    }
  }
}

.infoDiv {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid $gray60;
  border-radius: 10px;
}

.bottom {
  display: flex;
  flex-direction: column;
  background-color: $cardColor;
  border-radius: 10px;
  padding: 20px;
  margin-top: 10px;

  .profile {
    display: flex;
    flex-direction: row;
    align-items: center;

    > img {
      width: 100px;
    }

    > div {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  // position: absolute;
  // width: 100px;
  // height: 100px;
  background-color: #1e1f257d;
  z-index: 10;
  backdrop-filter: blur(5px);
  // overflow: hidden;
  border-radius: 10px;
}
