@import "../../globalStyles/StyleVariables.scss";

.inputPassStrong {
    border-bottom: 1px solid $gray80;

    .anticon svg {
        color: $gray60 !important;
    }
}

.inputPassStrong:focus-visible {
    border-bottom: 1px solid $accentColor;
}

.inputPassStrong:hover {
    border-bottom: 1px solid $accentColor;
}