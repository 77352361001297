@import '../../../../globalStyles/StyleVariablesLight.scss';

.pairItemDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;

  svg {
    font-size: 12px;
    color: gray;
    margin-right: 2px;
  }

  .pairCoinsDiv {
    display: flex;
    flex-direction: row;
    text-align: center;
    font-size: 10px;
    margin-right: 4px;
    margin-left: 2px;

    > span:nth-child(2) {
      color: rgb(132, 142, 156);
    }

    > span:nth-child(3) {
      color: rgb(132, 142, 156);
    }
  }

  .ratio {
    color: rgb(240, 185, 11);
    background-color: rgb(60, 38, 1);
    padding-left: 4px;
    padding-right: 4px;
    font-size: 12px;
    border-radius: 4px;
  }
}

.changeGreen {
  color: $greenPositive;
  font-size: 10px;
}
.changeRed {
  color: $redNegative;
  font-size: 10px;
}
