@import '../../../../../globalStyles/StyleVariables.scss';

.comboCustom {
  border-bottom: 1px solid $gray80;
}

.comboCustom:focus-visible {
  border-bottom: 1px solid $accentColor;
}

.comboCustom:hover {
  border-bottom: 1px solid $accentColor;
}
