@import "../../../../../globalStyles/StyleVariables.scss";

.sideBuy {
    color: $greenPositive;
}

.sideSell {
    color: $redNegative;
}

.expandTextContainer {
    padding: 10px;

    .title {
        color: $gray60;
    }

    .txt {
        color: $gray40;
    }

}