@import "../../../../../globalStyles//StyleVariables.scss";

.formBody {
    background-color: $cardColor;
    padding: 30px;
    border-radius: 10px;
    margin-top: 20px;
}

.formTitleDiv {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .formTitleBig {
        font-size: 20px;
        // font-weight: bold;
        color: $gray20;
    }

    .formTitleSmall {
        font-size: 12px;
        color: $gray20;
        opacity: 50%;
    }
}

.items {
    color: $gray60;
}

.picHelpText {
    color: $gray60;
    margin-top: 10px;
}

.uploadPhotoDiv {
    display: flex;
    flex-direction: column;
    justify-self: center;
    width: 192px;
    height: 192px;
    border: 1px solid $gray60;
    border-radius: 10px;
    padding: 10px;
    overflow: hidden;
    margin-top: 10px;
}

.indicatorDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 7px;
    justify-items: center;
    margin-top: 20px;
}
.indicatorItemGreen {
    border-radius: 10px;
    background-color: #30E0A1;
    width: 40px;
    margin-right: 10px;
}
.indicatorItemGray {
    border-radius: 10px;
    background-color: #5D6588;
    width: 40px;
    margin-right: 10px;
}

.uploadContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 10px;

    .uploadPhotoDiv {
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-items: center;
        justify-content: center;
        width: 210px;
        height: 210px;
        border: 1px solid $gray60;
        border-radius: 10px;
        padding: 10px;
        overflow: hidden;
        margin-right: 10px;
    }

    .deleteIconDiv {
        border: 1px solid $gray60;
        background-color: $cardColor;
        border-radius: 10px;
        padding: 7px 10px;
        color: $gray60;
        cursor: pointer;

        &:hover {
            border: 1px solid red;
            color: red;
        }
    }
}

.validationText {
    color: $redNegative;
}