@import "../../../../globalStyles/StyleVariables.scss";

.container {
    background-color: $cardColor;
    padding: 40px;
    border-radius: 10px;
}

.profilePicDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.infoDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $gray60;
    border-radius: 10px;
    padding: 10px;
    margin-top: 20px;
}


.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.profilePicDivMobile {
    display: flex;
    flex-direction: column;
    justify-items: center;
}

.inputsDiv {
    margin-bottom: 20px;
     :first-child {
        margin-bottom: 10px;
     }
}

.switchContainer {
    margin-bottom: 20px;

    .switchContainerTitle {
        // margin-bottom: 10px;
    }

    .switchItems {
        display: flex;
        flex-direction: row;
        margin-top: 15px;

        .switchItemsText {
            margin-left: 10px;
        }
    }
        
}
