@import '../../../../globalStyles/StyleVariables.scss';

.actionLink {
  color: #11cabe;
}

.actionLink:hover {
  color: rgb(17, 146, 138);
}

.deletRowBtn {
  color: $redNegative;
  font-size: 20px;
  cursor: pointer;

  &:hover {
    color: #e00034;
  }
}
