@import '../../../../globalStyles/StyleVariables.scss';

.container {
  background-color: $cardColor;
  padding: 40px;
  border-radius: 10px;
}

.providedKeyDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  color: $lightGreen;
  font-size: 18px;
}

.providedKeyDiv > img {
  margin-right: 10px;
}

.btnActivation {
  margin-left: 20px !important;
}

p {
  color: $gray40;
}

.collapse {
  svg {
    fill: #828bb4;
  }
}

.collapseActive {
  fill: #21cf11 !important;
  margin-left: 5px;
  margin-right: 5px;
}
