@import '../../../../../globalStyles/StyleVariables.scss';

.picDiv {
  padding: 10px;
  border: 1px solid #5d6588;
  border-radius: 5px;
  margin: 20px 0;
  width: 200px;
  height: 200px;
}
