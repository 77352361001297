.radioChoosePassport {
    .ant-radio-button-wrapper {
        display: flex;
        height: auto !important;
        padding: 10px !important;
        border-radius: 10px !important;
        margin-bottom: 10px;
    }

    .ant-radio-button-wrapper-checked {
        display: flex;
        border: 1px solid #F27F16 !important;
        color: #F27F16 !important;
    }
}