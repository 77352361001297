@import "../../../../../globalStyles//StyleVariables.scss";

// .tableStyle table {
//     // width: auto;
//     min-width: unset !important;

// }

.sideBuy {
    color: $greenPositive;
}

.sideSell {
    color: $redNegative;
}

.deleteRowButton {
    cursor: pointer;
    font-size: 20px;
    transition: all 0.6;

    &:hover {
        color: $redNegative;
        font-size: 20px;
    }
}