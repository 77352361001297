@import "../../../../../globalStyles/StyleVariables.scss";

.formBody {
    background-color: $cardColor;
    padding: 30px;
    border-radius: 10px;
    margin-top: 20px;
}

.bodyWithoutButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formTitleDiv {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .formTitleBig {
        font-size: 20px;
        // font-weight: bold;
        color: $gray20;
    }
}

.webCamHelpText {
    color: $gray60;
}

.webCamDiv {
    border: 1px solid $gray60;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    .webCam{
        width: 100%;
        height: 100%;
        margin-bottom: -4px;
    }

    .capturedPic {
        width: 100%;
        height: 100%;
    }
}

.items {
    color: $gray60;
    margin-top: 10px;
}

.validationText {
    color: $redNegative;
    margin-bottom: 10px;
}