@import "../globalStyles/StyleVariables.scss";

.ant-form-item-label>label.ant-form-item-required:before {
    content: unset !important;
}

.ant-menu-submenu-selected {
    border-left: 2px solid $gray60 !important;
    border-radius: 0px !important;
}

.ant-menu-item-selected {
    background-color: transparent !important;
    border-left: 2px solid white !important;
    border-radius: 0px !important;
    padding-left: 20px !important;
}

.ant-menu-item-group-title {
    color: #5D6588 !important;
}

.ant-menu-item:hover {
    padding-left: 20px !important;
    transition: all 0.6s !important;
}

@media (max-width: 767px) {
    .ant-picker-panels {
        flex-direction: column !important;
    }
}

.ant-form-item-with-help .ant-form-item-explain {
    font-size: 10px;
    margin-bottom: 10px;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.ant-select-selection-placeholder {
    color: $gray60 !important;
}

.ant-select-arrow {
    color: $gray60 !important;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    border: 0 !important;
    border-bottom: 1px solid red !important;
}