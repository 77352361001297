@import "../../../../../globalStyles//StyleVariables.scss";

.datePicketBasicAuth {
    .ant-picker-input {
        border-bottom: 1px solid $gray80 !important;

        >input::placeholder {
            color: $gray60 !important;
        }

        .ant-picker-suffix {
            color: $gray60 !important;
        }

        &:hover {
            border-bottom: 1px solid $accentColor !important;
        }

        &:hover {
            border-bottom: 1px solid $accentColor !important;
        }
    }

    .ant-picker-small {
        padding: 0 !important;
        width: 100% !important;
    }
}