@import "../../../../globalStyles/StyleVariables.scss";

.ant-slider-rail {
    background-color: $gray60;
    height: 2px;
}

.ant-slider-handle {
    background-color: $gray60;
    border: 0;
}

.sliderBuy {
    .ant-slider-dot {
        background-color: #00a86d;
        border: 0;
    }

    .ant-slider-track {
        background-color: #0ecb8338;
        height: 3px;
    }
}

.sliderSell {
    .ant-slider-dot {
        background-color: #cb0d39;
        border: 0;
    }

    .ant-slider-track {
        background-color: #f6465e39;
        height: 3px;
    }
}
