@import '../../../../globalStyles/StyleVariables.scss';

.container {
  border-left: 1px solid $gray80;
  border-right: 1px solid $gray80;
  // border-radius: 10px;
  // margin-top: 10px;
  padding: 10px;
}

.filtersDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: $gray40;
  font-size: 12px;
  height: 40px;

  > span {
    margin-right: 10px;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
  }
}

.avbl {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;
  margin-top: 10px;
}

.inputs {
  margin-bottom: 10px;
}

.inputDiv {
  margin-top: -13px;
}

.floatText {
  position: absolute;
  right: 10px;
  z-index: 1;
  top: 12px;
}

.btnGreen {
  background-color: #00a86d;
  color: white;
  border: 0;

  &:hover {
    background-color: #00a86d;
    color: white;
  }

  &:focus {
    background-color: #00a86d;
    color: white;
  }

  &:disabled {
    background-color: #00a86d;
    color: white;
  }
  &:disabled:hover {
    background-color: #00a86d;
    color: white;
  }
}

.btnRed {
  background-color: #fa2256;
  border: 0;
  color: white;

  &:hover {
    background-color: #cb0d39;
    color: white;
  }

  &:focus {
    background-color: #cb0d39;
    color: white;
  }

  &:disabled {
    background-color: #cb0d39;
    color: white;
  }
  &:disabled:hover {
    background-color: #cb0d39;
    color: white;
  }
}
